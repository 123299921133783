import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {AnimatePresence, motion} from "framer-motion";
import logo from "../../images/logo.json";
import Animation from "../Animation";

const list = {
  visible: {
    scaleY: 1,
    transition: {
      staggerChildren: 0.125,
      when: "beforeChildren",
      delay: 0,
    },
  },
  hidden: {
    scaleY: 0,
    transition: {
      staggerChildren: 0.125,
      when: "afterChildren",
      delay: 0,
    },
  },
};

const item = {
  visible: {
    scale: 1,
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: -25,
  },
};

const MobileNav = ({ nav, toggleNav, setTitle }) => {
  return (
    <nav className={"md:hidden flex flex-col h-12"}>
      <div className={"flex justify-between items-center"}>
        <Animation styles={"h-11 w-11"} data={logo} delay={1000} noExit/>
        <div
            className={`self-end text-5 z-10 mb-1 ${
                nav ? "text-gray-100" : "text-blue-400"
            }`}
        >
          <FontAwesomeIcon className={""} icon={faBars} onClick={toggleNav}/>
        </div>
      </div>
      <DropDown setTitle={setTitle} nav={nav} />
    </nav>
  );
};

const DropDown = ({ nav, setTitle }) => {
  return (
    <AnimatePresence>
      {nav && (
        <motion.div
          key="modal"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={list}
          className={
            "z-0 absolute list-none w-full h-44 bg-blue-400 top-0 left-0 flex flex-col text-3 text-gray-100 origin-top justify-center items-center"
          }
        >
          <motion.a
              onClick={() => {
              setTitle("Robert Moore | Services");
            }}
              variants={item}
              href={"#!services"}
          >
            Services
          </motion.a>
          <motion.a
              onClick={() => {
              setTitle("Robert Moore| Examples");
            }}
              variants={item}
              href={"#!examples"}
          >
            Examples
          </motion.a>
          <motion.a
              onClick={() => {
              setTitle("Robert Moore | FAQ");
            }}
              variants={item}
              href={"#!faq"}
          >
            FAQ
          </motion.a>
          <motion.a
              onClick={() => {
              setTitle("Robert Moore | Contact");
            }}
              variants={item}
              href={"#!contact"}
          >
            Contact
          </motion.a>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MobileNav;
