import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import "../styles/testimonials.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar, faStarHalf} from "@fortawesome/free-solid-svg-icons";
import FadeIn from "../hooks/FadeIn";

const Testimonials = () => {
  const testimonials = [
    {
      name: "Louise Humphris",
      company: "Just Stitchin'",
      text:
          "Robert went to great lengths to be able to resolve my website issue. His service was professional & of the highest standard. I highly recommend Robert Moore.",
      stars: [1, 1, 1, 1, 1],
    },
    {
      name: "Ross Presgrave",
      company: "KarmaWare",
      text:
          "Rob has been working closely with me on some app development - you won't be disappointed. He knows his stuff.",
      stars: [1, 1, 1, 1, 1],
    },
  ];

  return (
    <FadeIn>
      <div className="mx-2 lg:mt-8 xl:mt-16 2xl:mt-32">
        <Carousel
          showThumbs={false}
          autoPlay
          interval={5000}
          infiniteLoop
          dynamicHeight
          showArrows
          showStatus={false}
        >
          {testimonials.map((item, i) => {
            const { name, company, text, stars } = item;
            return (
              <div key={i}>
                <div
                    key={i.toString()}
                    className="px-8 md:px-16 h-96  flex flex-col self-center justify-center"
                >
                  <p className="text-3 xs:text-4 md:text-5 font-semibold">
                    {name}
                  </p>
                  <p className="text-1.5 xs:text-2 md:text-3 italic mt-2 ">
                    {company}
                  </p>
                  <p className="text-2 xs:text-3 md:text-4  font-medium md:mt-8">
                    {text}
                  </p>
                  <div className="flex justify-center space-x-2 my-4 text-3 md:text-4 lg:text-5 text-yellow-300 mt-4 md:mt-8">
                    {stars.map((star, index) =>
                      star === 1 ? (
                        <FontAwesomeIcon key={index.toString()} icon={faStar} />
                      ) : (
                        <FontAwesomeIcon
                          key={index.toString()}
                          icon={faStarHalf}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </FadeIn>
  );
};

export default Testimonials;
