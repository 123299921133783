import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDribbbleSquare, faFacebookSquare, faInstagramSquare,} from "@fortawesome/free-brands-svg-icons";
import {motion} from "framer-motion";
import FadeIn from "../hooks/FadeIn";
import Typed from "react-typed";

const Title = () => {
    return (
        <div className={"text-left"}>
            <Typed
                showCursor={false}
                className={
                    "text-3 2xl:text-left sm:text-4 lg:mb-1 2xl:mb-2 3xl:mb-12 font-medium inline"
        }
        delayStart={1000}
        strings={["Hi, I'm Robert Moore"]}
        typeSpeed={30}
      >
        <span />
      </Typed>

      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className={
          "2xl:mb-6  mb-4 text-6 mt-2 2xl:text-left sm:text-12 tiny:text-7 xs:text-9 xs:mb-4 sm:text-11 md:text-13 lg:text-14 2xl:text-11 3xl:text-14 text-blue-400 font-bold"
        }
      >
        Freelance <br /> Website Developer
      </motion.h1>

      <ul
        className={
          "mt-2 flex space-x-3 md:space-x-4 2xl:space-x-5 text-5 md:text-6 lg:text-8 3xl:text-10 "
        }
      >
        <FadeIn id={1} delay={1}>
          <a href={"https://www.facebook.com/robertmoorewebdev"}>
              <FontAwesomeIcon className={"text-blue-400"} icon={faFacebookSquare} />
          </a>
        </FadeIn>
        <FadeIn id={2} delay={1}>
            <a href={"https://www.instagram.com/rmwebdev/"}>
                <FontAwesomeIcon className={"text-green-400"} icon={faInstagramSquare} />
            </a>
        </FadeIn>
        <FadeIn id={3} delay={1}>
            <a href={"https://dribbble.com/RMWebDev"}>
                <FontAwesomeIcon className={"text-pink-400"} icon={faDribbbleSquare} />
            </a>
        </FadeIn>
      </ul>
    </div>
  );
};

export default Title;
