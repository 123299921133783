import React from "react";
import FadeIn from "../hooks/FadeIn";
import Animation from "./Animation";
const ServiceCard = ({ title, subtitle, text, id, Animation }) => {
  return (
    <FadeIn id={id}>
      <div
        className={
          "shadow py-3 px-4 md:px-6 md:py-5 lg:px-7 lg:py-6 rounded-sm "
        }
      >
        <div className={"flex items-start text-8 xs:text-10 lg:text-12"}>
           <Animation styles={"2xl:h-32 "} onExit />
          <div className={"ml-4 xs:ml-5"}>
            <h3
              className={
                "text-3 xs:text-4 lg:text-4 2xl:text-5 font-semibold tracking-wide"
              }
            >
              {title}
            </h3>
            <h4
              className={
                "text-1.5 lg:text-2 2xl:text-3 mb-1 font-medium tracking-wide"
              }
            >
              {subtitle}
            </h4>
          </div>
        </div>
        <p className={"text-2 2xl:text-3  mt-2 lg:mt-4 lg:text-2"}>{text}</p>
      </div>
    </FadeIn>
  );
};

export default ServiceCard;
