import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDribbbleSquare, faFacebookSquare, faInstagramSquare,} from "@fortawesome/free-brands-svg-icons";

const MobileFooter = () => {
  return (
      <footer
          className={"md:hidden flex flex-col text-center mt-8 xs:mt-8 mb-4"}
      >
        <a href={"tel:0400687285"} className={"text-2 xs:mb-2 xs:text-3 font-semibold"}>
          0400687285
        </a>
        <a href={"mailto:contact@robertmoorewebdev.com.au"} className={"text-2 xs:mb-2 xs:text-3 font-semibold"}>
          contact@robertmoorewebdev.com.au
        </a>
        <span className={"text-2 xs:text-3 font-semibold"}>
          ABN 29827284718
        </span>
        <ul className={"flex justify-center space-x-3 items-center text-6 mt-4"}>
          <li><a href={"https://www.facebook.com/robertmoorewebdev"}><FontAwesomeIcon className={"text-blue-400"} icon={faFacebookSquare}/></a></li>
          <li><a href={"https://www.instagram.com/rmwebdev"}><FontAwesomeIcon className={"text-green-400"} icon={faInstagramSquare}/></a></li>
          <li><a href={"https://dribbble.com/RMWebDev"}><FontAwesomeIcon className={"text-pink-400 "} icon={faDribbbleSquare}/></a></li>
        </ul>
        <p className={"text-1.3 xs:text-1.5"}>© 2021 ROBERT MOORE WEB DEV</p>
      </footer>
  );
};

export default MobileFooter;
