import React from "react";
import Subtitle from "./Subtitle";
import envelope from "../images/envelope.json";
import Animation from "./Animation";
import FadeIn from "../hooks/FadeIn";
import Paragraph from "./Paragraph";

import Button from "./Button";

const ContactForm = () => {

  return (
    <div>
      <div id={"!contact"} style={{ position: "relative", top: -50 }} />
      <FadeIn>
        <Subtitle>Contact Us</Subtitle>
      </FadeIn>
      <FadeIn>
        <Paragraph>
          I look forward to hearing from you. I am online every day and take
          pride in my quick response time. Click on the link below to reach me
          via email. Alternatively give me a call on{" "}
          <a className={"font-semibold"} href={"tel:0400687285"}>
            0400687285
          </a>
        </Paragraph>
      </FadeIn>
      <Animation
          styles={"mx-auto h-24 md:h-28 md:mt-6 mt-4 2xl:h-56 3xl:w-64 2xl:mt-8"}
          data={envelope}
      />
        <form name="contact" method="POST" data-netlify="true" action={"/success"}>
            <div id={"!contact"} style={{position: "relative", top: -50}}/>
            <input type="hidden" name="form-name" value="contact"/>
            <div className={"text-1.5 md:text-3 mt-8 2xl:w-3/5 mx-auto"}>
                <div className={"md:grid md:grid-cols-2 md:gap-8 "}>
                    <FadeIn id={1}>
                        <label
                            className={
                                "flex flex-col mt-4 xs:mt-6 white-bg p-2 2xl:px-4 px-3 w-full shadow rounded-sm"
                            }
                        >
                            Name*
                            <input
                                name={"name"}
                                className={
                                    "white-bg text-3 md:text-4 text-gray-600 font-semibold focus:outline-none"
                                }
                                type="text"
                            />
                        </label>
                    </FadeIn>
                    <FadeIn id={2}>
                        <label
                            className={
                                "flex flex-col mt-4 xs:mt-6 white-bg p-2 2xl:px-4 w-full shadow rounded-sm"
                            }
                        >
                            Business
                            <input
                                name={"business"}
                                className={
                                    "white-bg text-3 md:text-4 text-gray-600 font-semibold focus:outline-none"
                                }
                                type="text"
                            />
                        </label>
                    </FadeIn>
                </div>
                <div className={"md:grid md:grid-cols-2 md:gap-8"}>
                    <FadeIn id={3}>
                        <label
                            className={
                                "flex flex-col mt-4 xs:mt-6 white-bg p-2 2xl:px-4 w-full shadow rounded-sm"
                            }
                        >
                            Phone*
                            <input
                                name={"phone"}
                                className={
                                    "white-bg text-3 md:text-4 text-gray-600 font-semibold focus:outline-none"
                                }
                                type="tel"
                            />
                        </label>
                    </FadeIn>
                    <FadeIn id={4}>
                        <label
                            className={
                                "flex flex-col mt-4 xs:mt-6 white-bg p-2 2xl:px-4 w-full shadow rounded-sm"
                            }
                        >
                            Email*
                            <input
                                name={"email"}
                                className={
                                    "white-bg text-3 md:text-4 text-gray-600 font-semibold focus:outline-none"
                                }
                                type="email"
                            />
                        </label>
                    </FadeIn>
                </div>
                <FadeIn id={5}>
                    <label
                        className={
                            "flex flex-col mt-4 xs:mt-6 white-bg p-2 2xl:px-4 w-full shadow rounded-sm"
                        }
                    >
                        Message*
                        <textarea
                            name={"message"}
                            rows={4}
                            className={
                                "white-bg text-3 md:text-4 text-gray-600 font-semibold focus:outline-none"
                            }
                        />
                    </label>
                </FadeIn>
                <FadeIn id={6}>
                    <Button title={"SEND MESSAGE"} />
                </FadeIn>
            </div>
        </form>
    </div>
  );
};

export default ContactForm;
