import React from "react";

const Subtitle = ({ children }) => {
  return (
    <h2
      className={
        "mt-16 md:mt-24 text-5 xs:text-6 lg:text-8 2xl:mt-52 2xl:text-9 3xl:text-10 text-center font-semibold"
      }
    >
      {children}
    </h2>
  );
};

export default Subtitle;
