import React from "react";

const Button = ({ title, onSubmit }) => {
  return (
    <div className={"flex justify-center lg:mt-8"}>
      <button
        onSubmit={onSubmit}
        type={""}
        style={{ letterSpacing: "0.2rem" }}
        className={
          "focus:outline-none rounded-sm shadow mt-8 bg-blue-400 p-2 md:py-4 bg-w text-2 md:text-4 px-8 md:px-16 2xl:px-16 text-gray-100 font-bold"
        }
      >
        {title}
      </button>
    </div>
  );
};

export default Button;
