import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDribbbleSquare, faFacebookSquare, faInstagramSquare,} from "@fortawesome/free-brands-svg-icons";
import React from "react";

const DesktopFooter = () => {
  return (
      <footer
          className={
            "hidden tracking-wide md:flex flex-col items-end mt-8 xs:mt-16 2xl:mt-44"
          }
      >
        <a
        href={"tel:0400687285"}
        className={
          "transition duration-500 ease-in-out hover:text-blue-400 mb-4 text-3 font-bold"
        }
      >
        0400687285
      </a>
        <a
            href={"mailto:contact@robertmoorewebdev.com.au"}
            className={
              "transition duration-500 ease-in-out hover:text-blue-400 mb-4 text-3 font-bold"
            }
        >
          contact@robertmoorewebdev.com.au
        </a>
        <span className={"text-3 font-bold"}>ABN 29827284718</span>

      <div
        className={"flex justify-between items-end space-x-3 pl-4 pt-4 w-full"}
      >
        <ul
          className={
            "space-x-3 md:space-x-4 2xl:space-x-5 text-5 md:text-6 lg:text-8 flex justify-center items-center 2xl:mb-2"
          }
        >
          <li>
            <a href={"https://www.facebook.com/robertmoorewebdev"}>
              <FontAwesomeIcon
                  className={
                    "transition duration-500 ease-in-out hover:text-gray-600 text-blue-400 "
                  }
                  icon={faFacebookSquare}
              />
            </a>
          </li>
          <li>
            <a href={"https://www.instagram.com/rmwebdev/"}>
              <FontAwesomeIcon
                  className={
                    "transition duration-500 ease-in-out text-green-400 hover:text-gray-600"
                  }
                  icon={faInstagramSquare}
              />
            </a>
          </li>
          <li>
            <a href={"https://dribbble.com/RMWebDev"}>
              <FontAwesomeIcon
                  className={
                    "transition duration-500 ease-in-out text-pink-400 hover:text-gray-600"
                  }
                  icon={faDribbbleSquare}
              />
            </a>
          </li>
        </ul>
        <div className={"space-x-6"}>
          <span className={"text-1.3 xs:text-2"}>
            © 2021 ROBERT MOORE WEB DEV
          </span>
        </div>
      </div>
    </footer>
  );
};
export default DesktopFooter;
