import React from "react";
import FadeIn from "../hooks/FadeIn";
const QuestionCard = ({ question, answer, id }) => {
  return (
    <FadeIn id={id}>
      <div className={"shadow py-3 px-4 md:px-6 md:py-5 lg:px-7 lg:py-6"}>
        <div className={"flex text-8"}>

          <div>
            <h3
              className={
                "text-3 xs:text-3 lg:text-4 2xl:text-5 font-semibold tracking-wide"
              }
            >
              <span className={"text-blue-400 font-black tracking-wide mr-3"}>Q: </span>{question}
            </h3>
          </div>
        </div>
        <p className={"text-2 2xl:text-3 mt-2 lg:mt-4"}>{answer}</p>
      </div>
    </FadeIn>
  );
};

export default QuestionCard;
