import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import FadeIn from "../hooks/FadeIn";

const LightBox = ({ image, alt, id }) => {
  const [open, toggleOpen] = useState(false);
  return (
    <div>
      {open ? (
        <Lightbox mainSrc={image} onCloseRequest={() => toggleOpen(false)} />
      ) : null}
      <FadeIn id={id}>
        <img
          onClick={() => {
            toggleOpen(true);
          }}
          alt={alt}
          className={"mt-4 xs:mt-6 md:mt-8 shadow "}
          src={image}
        />
      </FadeIn>
    </div>
  );
};

export default LightBox;
