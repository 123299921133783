import * as React from "react";
import {useState} from "react";
import PageLayout from "../components/PageLayout";
import MobileNav from "../components/navigation/MobileNav";
import Title from "../components/Title";
import Paragraph from "../components/Paragraph";
import SmallCards from "../components/SmallCards";
import Subtitle from "../components/Subtitle";
import Section from "../components/Section";
import ServiceCard from "../components/ServiceCard";
import QuestionCard from "../components/QuestionCard";
import Testimonials from "../components/Testimonials";
import ContactForm from "../components/ContactForm";
import "react-image-lightbox/style.css";
import {faPencilRuler} from "@fortawesome/free-solid-svg-icons";
import {motion} from "framer-motion";
import website_1 from "../images/website-1.png";
import website_2 from "../images/website-2.png";
import website_3 from "../images/website-3.png";
import MobileFooter from "../components/navigation/MobileFooter";
import DesktopNav from "../components/navigation/DesktopNav";
import DesktopFooter from "../components/navigation/DesktopFooter";
import LightBox from "../components/LightBox";
import ScrollTrigger from "react-scroll-trigger";
import FadeIn from "../hooks/FadeIn";
import {Helmet} from "react-helmet";
import Animation from "../components/Animation";
import ecommerce from "../images/ecommerce.json";
import seo from "../images/seo.json";
import web_design from "../images/webdesign.json";
import web_dev from "../images/webdev.json";
import branding from "../images/branding.json";
import hosting from "../images/hosting.json";

const IndexPage = () => {
  const year = new Date().getFullYear();
  const [nav, toggleNav] = useState(false);
  const [title, setTitle] = useState(
    `Robert Moore | Website Development | ${year}`
  );

  return (
    <PageLayout>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="language" content="English" />
        <meta name="author" content="Robert Moore" />
        <meta
          name="keywords"
          content="website, freelance, developer, designer, robert, moore, rob, darwin, australia, development"
        />
        <meta name="robots" content="index, follow" />
        <meta name="revisit-after" content="1 days" />
        <meta
          name="description"
          content="Robert Moore Web Development offers unique, custom and modern websites
          for businesses, blogs, portfolios and more."
        />
        <meta
          name="google-site-verification"
          content="3NAbJ5tfZO8TzVSzl0oEhUwA1WlYqndNUBAkTSn26cw"
        />
        <meta httpEquiv={"ScreenOrientation"} content="autoRotate:disabled" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-HWMYCRT0T9"
        />
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-HWMYCRT0T9');
          `}
        </script>
      </Helmet>
      <ScrollTrigger
        onExit={() => {
          toggleNav(false);
        }}
      >
        <MobileNav
          setTitle={setTitle}
          nav={nav}
          toggleNav={() => {
            toggleNav(!nav);
          }}
        />
      </ScrollTrigger>
      <DesktopNav setTitle={setTitle} />
      <div
        id={"home"}
        className={
          "mt-4 xs:mt-8 mb-16  md:mt-16 lg:mt-16 2xl:mt-40 2xl:grid 2xl:grid-cols-2 2xl:gap-24 2xl:justify-between 2xl:items-start "
        }
      >
        <div>
          <h1>{process.env.GATSBY_TEST}</h1>
          <Title/>
          <motion.p
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              transition={{delay: 0.5, duration: 0.5}}
              className={
                "text-2 sm:w:4/5 text-leftmx-auto md:text-left md:mx-0 w-6/7 mt-8 md:mt-16 2xl:text-3 2xl:w-full tiny:mt-12md:mt-8 lg:mt-16  lg:text-left font-medium"
              }
          >
            I am a freelance website developer based in Darwin, NT. I have over
            6 years experience developing responsive custom websites with the
            latest technologies. Whether you’ve decided to take the plunge and
            start a new business or want to bring your great idea to life, I can
            help. My passion is creating professional websites at affordable
            prices. Please support a small local business.
          </motion.p>
        </div>
        <SmallCards toggleNav={toggleNav} />
      </div>
      <Services />
      <Examples/>
      <Faq/>
      <div className={"mt-16 md:mt-16"}>
        <Testimonials/>
      </div>
      <ContactForm/>
      <MobileFooter/>
      <DesktopFooter />
    </PageLayout>
  );
};

const Examples = () => {
  return (
    <Section>
      <div id={"!examples"} style={{ position: "relative", top: -50 }} />
      <FadeIn>
        <Subtitle>Examples</Subtitle>
      </FadeIn>
      <FadeIn>
        <Paragraph>
          Here are just a few examples of different websites I can create for
          you. Are you wanting an exciting blog page or a professional website
          for your business? I can help you to make your idea a reality.
        </Paragraph>
      </FadeIn>
      <div className={"lg:grid lg:grid-cols-3  mx-auto gap-8 mt-8"}>
        <LightBox alt={"Ruby's Hairdressers"} id={0} image={website_1} />
        <LightBox alt={"SMARAGD Jewellery"} id={1} image={website_2} />
        <LightBox alt={"EMC Electrical"} id={2} image={website_3} />
      </div>
    </Section>
  );
};

const Services = () => {
  const services = [
    {
      id: 1,
      Animation: () => (
          <Animation delay={500} data={web_design} styles={"h-16 w-16"}/>
      ),
      icon: faPencilRuler,
      title: "Website Design",
      subtitle: "Beautiful layouts & modern designs",
      text:
          "I can create beautiful, pixel-perfect websites that stand out from the crowd. Using modern techniques, I can turn your ideas into a reality. I can create Blogs, Galleries, Business Pages and much more. No matter how big or small your project, I can help you turn it into something wonderful.",
    },
    {
      id: 2,
      Animation: () => (
          <Animation delay={750} data={web_dev} styles={"h-16 w-16"}/>
      ),
      icon: faPencilRuler,
      title: "Website Development",
      subtitle: "Fast, responsive & professional websites",
      text:
          "Using the latest technologies, I can turn your designs into a fully functioning state-of-the-art website. The final product will be a 100% custom coded website, tailored to your specific requirements. I can create interactive components such as navigations, buttons, galleries and more. Any feature you want, I have got you covered.",
    },
    {
      id: 3,
      Animation: () => (
        <Animation delay={1000} data={branding} styles={"h-16 w-16"} />
      ),
      icon: faPencilRuler,
      title: "Branding & Animation",
      subtitle: "Giving your website the fresh look it deserves",
      text:
        "If you are in the process of creating your new business and need a logo, colour scheme or graphics I can take care of it. I also provide spectacular custom designed animations to make your website pop.",
    },
  ];

  const services2 = [
    {
      id: 4,
      Animation: () => (
          <Animation delay={1250} data={seo} styles={"h-16 w-16"}/>
      ),
      icon: faPencilRuler,
      title: "SEO Solutions",
      subtitle: "Shining a spotlight on your website",
      text:
          "In today’s online world the competition has never been greater. It is important that your website is easily found. Using modern Search Engine Optimisation techniques, I promise to help get your website the traffic it deserves.",
    },
    {
      id: 5,
      Animation: () => (
          <Animation delay={1500} data={hosting} styles={"h-16 w-16"}/>
      ),
      icon: faPencilRuler,
      title: "Email & Hosting",
      subtitle: "Fast, reliable website & email hosting",
      text:
          "If you require website hosting, I provide a variety of different packages to ensure your website is reliable and loads quickly. No matter the size or complexity of the website, my hosting will help make sure it survives the test of time. I also provide business email solutions for any sized team.",
    },
    {
      id: 6,
      Animation: () => (
          <Animation delay={1750} data={ecommerce} styles={"h-16 w-16"}/>
      ),
      icon: faPencilRuler,
      title: "E-Commerce",
      subtitle: "Giving your business a platform for success",
      text:
          "If your business requires an online store, I can provide a fantastic solution with a content management system including registrations and logins. I can arrange a seamless experience for your customers to purchase your latest products with ease.",
    },
  ];

  return (
    <Section>
      <div id={"!services"} style={{ position: "relative", top: -50 }} />
      <FadeIn id={1}>
        <Subtitle>Services</Subtitle>
      </FadeIn>
      <FadeIn id={2}>
        <Paragraph>
          I can provide professional custom-designed websites to meet every
          budget. Whether you have decided to take the plunge and start a new
          business or want to do a complete redesign of your business, I can
          help. I can also provide ongoing support and updates.
        </Paragraph>
      </FadeIn>
      <div className={"grid md:grid-cols-2 gap-8 mt-16"}>
        <div className={"flex flex-col gap-y-8"}>
          {services.map((item) => (
            <ServiceCard key={item.id.toString()} {...item} />
          ))}
        </div>
        <div className={"flex flex-col gap-y-8"}>
          {services2.map((item) => (
            <ServiceCard key={item.id.toString()} {...item} />
          ))}
        </div>
      </div>
    </Section>
  );
};

const Faq = () => {
  const questions = [
    {
      id: 1,
      question: "What do I need to provide to get started?",
      answer:
        "If you do not have any current content, I do provide a content creation service. Otherwise, to begin designing and developing your website I need all text, images, branding and all other media.",
    },
    {
      id: 2,
      question: "How much will my website cost?",
      answer:
          "There is no simple answer to this question. Generally, one of my websites will cost between $1000 and $3000. A basic website would have a different price in comparison to a website that involves more complex features such as login systems, content management and animations. The quickest way to work out the cost is to contact me for a free quote.",
    },
    {
      id: 3,
      question: "Why do I need a new website?",
      answer:
          "If your website looks and feels outdated, you could lose potential customers. Remember that most times your website is what people first see when they look at your business. You want them to be able to see clearly what your business has to offer. The average user will move on from your website if your content takes more than 2 seconds to load. Don’t miss out on potential customers because of slow load times and outdated designs. ",
    },
  ];

  const questions2 = [
    {
      id: 4,
      question: "Will you maintain my website?",
      answer:
          "Yes, I can maintain your website. I provide website maintenance packages for all budgets. Contact me to see which package best suits your needs.",
    },
    {
      id: 5,
      question:
          "How long does it take to change/add something to my website after initial completion?",
      answer:
          "There are no one-size fits all answer to this question, however on average I will start working on the change the following working day. The length of time for the update to be completed will depend on the scope and complexity of the revision.",
    },
    {
      id: 6,
      question: "Will my website work on multiple devices?",
      answer:
        "Yes, I work to ensure that your website will be able to be viewed on all devices and be 100% responsive. This includes desktops, laptops, tablets, mobile phones and all browsers at any resolution.",
    },
  ];
  return (
    <Section>
      <div id={"!faq"} style={{ position: "relative", top: -50 }} />
      <FadeIn>
        <Subtitle>Frequently Asked Questions</Subtitle>
      </FadeIn>
      <FadeIn>
        <Paragraph>
          Here is a list of some frequently asked questions, these should help
          answer most of your queries. If you have any other questions please
          feel free to contact me.
        </Paragraph>
      </FadeIn>
      <div className={"grid md:grid-cols-2 gap-8 mt-16"}>
        <div className={"flex flex-col gap-y-8"}>
          {questions.map((item) => (
            <QuestionCard key={item.id.toString()} {...item} />
          ))}
        </div>
        <div className={"flex flex-col gap-y-8"}>
          {questions2.map((item) => (
            <QuestionCard key={item.id.toString()} {...item} />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default IndexPage;
