import React from "react";
import Animation from "../Animation";
import logo from "../../images/logo.json";
import {motion} from "framer-motion";

const DesktopNav = ({ setTitle }) => {
  return (
    <nav className={"hidden md:flex justify-between items-center h-12"}>
      <Animation
          noExit={true}
          styles={"h-12 w-12 lg:w-16 lg:h-16 2xl:h-20 2xl:w-20"}
          data={logo}
          delay={1000}
      />
      <motion.div
        initial={{ opacity: 0, x: 300 }}
        animate={{ opacity: 1, x: 0, duration: 0.5 }}
        className={
          "space-x-6 2xl:space-x-12 text-1.3 md:text-1.5 2xl:text-3 font-medium tracking-widest text-blue-400 uppercase"
        }
      >
        <a
          onClick={() => {
            setTitle("Robert Moore | Services");
          }}
          href={"#!services"}
        >
          Services{" "}
        </a>
        <a
          onClick={() => {
            setTitle("Robert Moore | Examples");
          }}
          href={"#!examples"}
        >
          Examples
        </a>
        <a
          onClick={() => {
            setTitle("Robert Moore | FAQ");
          }}
          href={"#!faq"}
        >
          FAQ
        </a>
        <a
          onClick={() => {
            setTitle("Robert Moore | Contact");
          }}
          href={"#!contact"}
        >
          Contact
        </a>
      </motion.div>
    </nav>
  );
};

export default DesktopNav;
