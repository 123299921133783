import React, { useEffect, useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import { motion, useAnimation } from "framer-motion";

const FadeIn = ({ children, styles, id, delay, toggleNav, noY }) => {
  const [play, setPlay] = useState(false);
  const controls = useAnimation();
  useEffect(() => {
    if (play) {
      controls
        .start({
          opacity: 1,
            y: 0,
          transition: { delay: id ? 0.08 * id : 0 + delay, duration: 0.20 },
        })
        .then(() => toggleNav);
    } else {
        controls
            .start({
                opacity: 0,
                y: noY ? 0 : -25,
                transition: { opacity: {delay: 0.6},  delay: id ? 0.08 * id : 0 + delay, duration: 0.20 },
            })
            .then(() => toggleNav);
    }
  }, [play, controls, delay, id, noY,toggleNav]);

  return (
    <ScrollTrigger onEnter={() => setPlay(true)} onExit={() => setPlay(false)}>
      <motion.div
        style={{ opacity: 0 }}
        animate={controls}
        className={styles}
        custom={0}
      >
        {children}
      </motion.div>
    </ScrollTrigger>
  );
};

export default FadeIn;
