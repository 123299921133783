import React from "react";

const Paragraph = ({ children }) => {
  return (
  <div className={"md:w-3/4 mx-auto"}>
      <p
          className={
              "text-2 w-full text-left w-4/5 2x:w-3/4 mx-auto 2xl:text-3 mt-4 tiny:mt-6 md:mt-8 lg:mt-16 md:text-center"
          }
      >
          {children}
      </p>
  </div>
  );
};

export default Paragraph;
