import React from "react";
import fingerprint from "../images/fingerprint.json";
import modern from "../images/modern.json";
import custom from "../images/custom.json";
import FadeIn from "../hooks/FadeIn";
import Animation from "./Animation";

const SmallCards = (props) => {
  return (
    <div
      className={
        "grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-1 2xl:gap-12 gap-8 mt-8 md:mt-12 lg:mt-24 2xl:mt-0 w-full"
      }>
      <Card
        {...props}
        id={1}
        delay={0}
        animation={fingerprint}
        color={"text-green-300"}
        title={"UNIQUE"}
        styles={"h-20 w-20 xs:w-20 w-20"}
        text={"Make your website stand out from the crowd with a unique design"}
      />
      <Card
        id={2}
        delay={750}
        animation={modern}
        color={"text-indigo-300"}
        title={"MODERN"}
        styles={"h-20 w-20 xs:w-20 w-20"}
        text={
          "Sit back and relax knowing your website was built with the latest technologies"
        }
      />
      <Card
        id={3}
        delay={1500}
        animation={custom}
        color={"text-blue-300"}
        title={"CUSTOM"}
        styles={"h-20 xs:h-20 xs:w-20 w-20 p-2"}
        text={
          "I can deliver a pixel-perfect website to reflect your audiences wishes."
        }
      />
    </div>
  );
};

export default SmallCards;

const Card = ({ animation, title, text, toggleNav, id, delay, styles }) => {
  return (
    <FadeIn id={id} toggleNav={toggleNav}>
      <div
        className={
          "flex mt-8 flex-col mx-auto justify-center  md:justify-end md:flex-col items-center 2xl:flex-row mt-8 xl:mt-8 2xl:mt-0 md:ml-4"
        }
      >
        <div
          className={
            "h-32 2xl:mr-16 tiny:h-36 tiny:w-36 md:w-44 md:w-h-44 flex flex-col justify-center items-center  text-8 xs:text-10 md:text-12 shadow rounded-md p-4"
          }
        >
         <Animation onExit data={animation} delay={delay} styles={styles} />
          <p
            style={{ letterSpacing: "0.3rem" }}
            className={"text-2 tiny:mt-3 xs:text-1.5 font-bold "}
          >
            {title}
          </p>
        </div>
        <p
          className={
            "text-2 mt-8 2xl:mt-0  2xl:text-3 2xl:w-1/2 text-center 2xl:text-left font-medium tracking-wide md:ml-0"
          }
        >
          {text}
        </p>
      </div>
    </FadeIn>
  );
};
